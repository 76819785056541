import React from 'react';
import "./about.css";
let tickerInterval;
let currentInterval = 0;
let abouts = [
  "we create awesome games deep in the forests of Northern Sweden",
  "we are an independent studio that develop games for many platforms",
  "we build games with our own engine, in our own way, backed by two decades of knowledge",
  "<h1>we are nethash</h1>"
];

export class About extends React.Component {
  updateAboutText() {
    let e = document.getElementById("AboutTicker");
    if (e) {
      e.innerHTML = abouts[currentInterval];
      currentInterval++;
      if (currentInterval >= abouts.length) {
        currentInterval = 0;
      }
    }
  }
  componentDidMount() {
    let e = document.getElementById("AboutTicker");
    if (e) {
      e.innerHTML = abouts[currentInterval];
    }
    if (!tickerInterval) {
      tickerInterval = setInterval(this.updateAboutText, 6000);
    }
  }
  componentDidUnMount() {
    clearInterval(tickerInterval);
  }
  render() {
    return(
      <div className="Main-Information">
        <h3 id="AboutTicker">We create awesome games deep in the forests of Northern Sweden</h3>
      </div>
    );
  }
}

export default About;
