import React from 'react';
import "./forest.css";
import r1 from '../img/rocket1.png';
import r2 from '../img/rocket2.png';
import r3 from '../img/rocket3.png';
let rocketFrames = [r1,r2,r3];
let rocketTicker = 1;
export class Forest extends React.Component {
  animateRocket() {
    let rd = document.getElementById("rocket");
    if (rd) {
      rd.src = rocketFrames[rocketTicker];
    }
    rocketTicker++;
    if (rocketTicker > 2) {
      rocketTicker = 0;
    }
  }
  componentDidMount() {
    setInterval(this.animateRocket,100);
  }
  render() {
    return(
      <div className="Forest-Footer">
        <div id="rocketdiv" className="Rocket-Container">
          <img id="rocket" alt="tiny spesssheep" className="Rocket-Sprite" src={r1}/>
        </div>
      </div>
    );
  }
}
export default Forest;
