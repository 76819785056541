import React from 'react';
import "./header.css";

import discordLogo from '../img/Discord-Logo-White.svg';
import twitterLogo from '../img/witter-3.svg';
export class Header extends React.Component {
  render() {
    return(
      <header className="App-header">
        <h1 className="Logo-Text"><a href="/">nethash</a></h1>
        <div className="Discord-Container">
          <a href="https://discord.gg/hCyZj8E"><img className="Discord-Logo" src={discordLogo} alt="Join Discord!"/></a>
          <a href="https://twitter.com/NethashStudio"><img className="Twitter-Logo" src={twitterLogo} alt="Follow Us On Twitter!"/></a>
        </div>
      </header>
    );
  }
}
export default Header;
