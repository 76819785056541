import React from 'react';
export class Contact extends React.Component {
  render() {
    return(
      <div className="Main-Information">
        <h3><a href="mailto:development@nethash.se">development@nethash.se</a></h3>
      </div>
    );
  }
}
export default Contact;
