import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {About} from './components/about.js';
import {Games} from './components/games.js';
import {Contact} from './components/contact.js';
import {Header} from './components/header.js';
import {Forest} from './components/forest.js';
import {Menu} from './components/menu.js';
import './App.css';
import './components/background.css';

export class App extends React.Component {
  getRandomBackground() {
    let backgroundClasses = ["bgblue","bgbabyblue","bgdeepblue","bgpurple"];
    return backgroundClasses[Math.floor(Math.random()*backgroundClasses.length)];
  }
  componentDidMount() {
    document.body.classList.add(this.getRandomBackground());
  }
  render(){
    return (

      <div className="App">
        <div className="Hello maever from Hoffa#0256"><p></p></div>
        <div className="Background-Container">
          <Header/>
          <Router>
            <Menu/>
            <Switch>
              <Route path="/games" component={Games}></Route>
              <Route path="/contact" component={Contact}></Route>
              <Route path="/" component={About}></Route>
            </Switch>
          </Router>
          <div className="big"></div>
          <div className="medium"></div>
          <div className="small"></div>
        </div>
        <Forest/>
      </div>
    );
  }
}

export default App;
