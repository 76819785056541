import React from 'react';
import "./games.css";
import rustRacers from "../img/LOGO_Blackbackground_512x512.png";

export class Games extends React.Component {
  render() {
    return(
      <div className="Main-Information">
        <a className="Game-Link" rel="noopener noreferrer" target="_blank" href="https://www.rustracers.net"><img alt="Rust Racers Banner" className="Game-Banner" src={rustRacers}/></a>
      </div>
    )
  }
}
export default Games;
