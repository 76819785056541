import React from 'react';
import {Link} from 'react-router-dom';
import "./menu.css";
export class Menu extends React.Component {
  render() {
    return(
      <div className="Main-Menu">
        <nav>
          <ul>
            <li>
              <Link to="/">ABOUT<span aria-label="question mark emoji" role="img">❓</span></Link>
            </li>
            <li>
              <Link to="/games">GAMES<span aria-label="video game controller emoji" role="img">🎮</span></Link>
            </li>
            <li>
              <Link to="/contact">CONTACT<span role="img" aria-label="email emoji">📧</span></Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
export default Menu;
